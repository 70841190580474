function getServerURL() {
  const localhost = 'http://localhost:3131'
  const staging = 'https://api-journal.cloudxier.com'
  const production = 'https://api-journal.cloudxier.com'
  let host = window.location.host;
  if (host) {
    host = host.toLowerCase();
  }
  let env
  switch (host) {
    case "journal-admin.cloudxier.com":
      env = staging
      break;
    case "journal.sustainability.co.id":
      env = production
      break;
    default:
      env = localhost
  }
  return env
};

export default getServerURL