import React from "react";
import { CCol, CContainer, CRow, CImage, CButton } from "@coreui/react-pro";
import logo from "src/assets/brand/cloudxier_logo.png";

const RegisterSuccess = (props) => {
  const { navigate, email, emailStorage } = props;
  return (
    <CContainer>
      <CRow className="justify-content-center">
        <CImage
          src={logo}
          height={35}
          style={{
            filter: "invert(100%)",
            objectFit: "contain",
            top: "9%",
            position: "absolute",
          }}
        />
      </CRow>
      <CRow className="justify-content-center">
        <CCol
          md={6}
          className="d-flex flex-column align-items-center justify-content-center"
        >
          <h2
            style={{
              color: "#345AE0",
              marginBottom: "1.75em",
            }}
          >
            Thank you for registering at BSSE
          </h2>
          <div
            style={{
              color: "#000000",
              fontSize: "17px",
              fontWeight: "400",
              fontStyle: "normal",
              textAlign: "center",
              marginBottom: "1em",
            }}
          >
            Account activation link has been sent to your email address:{" "}
            <b>
              {email ? email : emailStorage} Please check your inbox and verify
              your email address.
            </b>
          </div>
          <CButton
            color="dark"
            className="px-4"
            variant="outline"
            type="submit"
            style={{
              marginTop: "3em",
              borderWidth: "1.5px",
            }}
            onClick={() => navigate("/")}
          >
            <div
              style={{
                color: "#000000",
                fontWeight: "400",
              }}
            >
              Back to website
            </div>
          </CButton>
        </CCol>
      </CRow>
    </CContainer>
  );
};

export default RegisterSuccess;
