import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  CButton,
  CCard,
  CCardBody,
  CCol,
  CContainer,
  CForm,
  CFormInput,
  CFormLabel,
  CImage,
  CRow,
} from "@coreui/react-pro";
import Cookies from "js-cookie";
import Axios from "src/helpers/axios";
import logo from "src/assets/brand/cloudxier_logo.png";

const ForgotPassword = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const token = Cookies.get("ut");

  const [email, setEmail] = useState("");
  const [invalidEmail, setInvalidEmail] = useState(false);
  const [invalidEmailMsg, setInvalidEmailMsg] = useState("");

  const [successForgotPasswordModal, setSuccessForgotPasswordModal] =
    useState(false);

  useEffect(() => {
    if (token) {
      Axios({
        method: "get",
        url: "/users",
      })
        .then(({ data }) => {
          if (data) {
            navigate("/");
          }
        })
        .catch((err) => {
          console.log("Error:::", err);
        });
    }
  }, []);

  const sendConfirmation = () => {
    Axios({
      method: "post",
      url: "/users/forgot/verify",
      data: {
        email: email,
      },
    })
      .then(() => {
        dispatch({
          type: "SET_TOAST_MESSAGE",
          toastMessage: {
            isError: false,
            isShown: true,
            color: "success",
            text: "Password reset link has been sent to your email",
          },
        });
        setSuccessForgotPasswordModal(true);
      })
      .catch((err) => {
        const errMessage =
          err && err.response && err.response.data && err.response.data.message;
        console.log("Error:::", errMessage);
        if (errMessage) {
          dispatch({
            type: "SET_TOAST_MESSAGE",
            toastMessage: {
              isError: true,
              isShown: true,
              color: "danger",
              text: errMessage,
            },
          });
        }
      });
  };

  const validateEmail = (email) => {
    const validEmailRegex =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9.-]+\.[a-zA-Z0-9-]{2,6}$/;
    if (email.match(validEmailRegex)) {
      return true;
    } else {
      return false;
    }
  };

  const handleValidation = () => {
    let emailValidation = false;
    let emailValidationMsg = "";

    if (!email) {
      emailValidationMsg = "Please enter your email";
      emailValidation = true;
    } else {
      const isValidEmail = validateEmail(email);
      if (!isValidEmail) {
        emailValidationMsg = "Please enter a valid email format";
        emailValidation = true;
      }
    }
     
    setInvalidEmail(emailValidation);
    setInvalidEmailMsg(emailValidationMsg);

    if (emailValidation !== true) {
      return true;
    } else {
      return false
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const isValid = handleValidation();
    if (isValid) {
      sendConfirmation();
    }
  };

  return (
    <div className="bg-light min-vh-100 d-flex flex-row align-items-center">
      {successForgotPasswordModal ? (
        <CContainer>
          <CRow className="justify-content-center">
            <CImage
              src={logo}
              height={35}
              style={{
                filter: "invert(100%)",
                objectFit: "contain",
                top: "9%",
                position: "absolute",
              }}
            />
          </CRow>
          <CRow className="justify-content-center">
            <CCol
              md={5}
              className="d-flex flex-column align-items-center justify-content-center"
            >
              <h2
                style={{
                  color: "#345AE0",
                  marginBottom: "1em",
                }}
              >
                An email has been sent
              </h2>
              <div
                style={{
                  color: "#000000",
                  fontSize: "17px",
                  fontWeight: "400",
                  fontStyle: "normal",
                  textAlign: "center",
                }}
              >
                Please check your inbox, we have sent a password recovery
                instruction to your email
              </div>
            </CCol>
          </CRow>
        </CContainer>
      ) : (
        <CContainer>
          <CRow className="justify-content-center">
            <CImage
              src={logo}
              height={35}
              style={{
                filter: "invert(100%)",
                objectFit: "contain",
                top: "9%",
                position: "absolute",
              }}
            />
          </CRow>
          <CRow className="justify-content-center">
            <CCol md={9} lg={7} xl={6}>
              <CCard className="mx-4">
                <CCardBody className="p-4">
                  <CForm
                    className="row g-3 needs-validation"
                    onSubmit={handleSubmit}
                  >
                    <h3 style={{ color: "#345AE0" }}>Forgot Password ?</h3>
                    <div
                      style={{
                        color: "#000000",
                        fontSize: "17px",
                        fontWeight: "700",
                        fontStyle: "normal",
                        marginBottom: "2em",
                      }}
                    >
                      No worries, we will send you a reset instructions.
                    </div>
                    <CRow className="">
                      <CCol lg="12">
                        <div className="mb-3">
                          <CFormLabel
                            style={{ fontWeight: "500", fontSize: "16px" }}
                          >
                            Email Address
                          </CFormLabel>
                          <CFormInput
                            placeholder="Enter your email credentials"
                            autoComplete="email"
                            type="email"
                            value={email}
                            onChange={({ target: { value } }) =>
                              setEmail(value)
                            }
                            invalid={invalidEmail}
                            feedbackInvalid={invalidEmailMsg}
                          />
                        </div>
                      </CCol>
                    </CRow>
                    <div className="d-grid gap-2 col-6 mx-auto">
                      <CButton
                        color={!email ? "secondary" : "primary"}
                        variant="outline"
                        type="submit"
                        style={{ marginBottom: "0.3em" }}
                        disabled={!email}
                      >
                        Reset password
                      </CButton>
                      <CButton
                        color="secondary"
                        onClick={() => navigate("/login")}
                      >
                        <div style={{ color: "#FFFFFF" }}>Back to login</div>
                      </CButton>
                    </div>
                  </CForm>
                </CCardBody>
              </CCard>
            </CCol>
          </CRow>
        </CContainer>
      )}
    </div>
  );
};

export default ForgotPassword;
