import React from "react";
import {
  CButton,
  CCard,
  CCardBody,
  CCol,
  CContainer,
  CMultiSelect,
  CFormLabel,
  CFormInput,
  CRow,
  CDatePicker,
  CDropdown,
  CDropdownToggle,
  CInputGroup,
  CFormSelect,
  CImage,
  CBadge,
  CFormCheck,
} from "@coreui/react-pro";
import CIcon from "@coreui/icons-react";
import { cilPlus, cilXCircle } from "@coreui/icons";
import logo from "src/assets/brand/cloudxier_logo.png";

// React Google Recaptcha
import ReCAPTCHA from "react-google-recaptcha";

const RegisterSlideTwo = (props) => {
  const {
    // state
    firstName,
    lastName,
    countryOptions,
    countries,
    isOpenDropdownPhoneNumber,
    dateOfBirth,
    gender,
    jobOrOcupation,
    phoneNumber,
    affiliation,
    location,
    address,
    orchidId,
    country,
    howDoYouHearOptions,
    selectedKeywordBSSE,
    keywordsBSSE,
    universityOptions,
    selectedKeywordAffiliationWebUrl,
    invalidAffiliationWebUrl,
    keywordsAffiliationWebsiteURL,
    invalidFirstNameMsg,
    invalidLastNameMsg,

    // state setter
    setFirstName,
    setLastName,
    setIsOpenDropdownPhoneNumber,
    setDateOfBirth,
    setGender,
    setJobOrOcupation,
    setPhoneNumber,
    setAffiliation,
    setLocation,
    setAddress,
    setOrchidId,
    setCountry,
    setSelectedKeywordBSSE,
    setSelectedKeywordAffiliationWebUrl,
    setInvalidFirstNameMsg,
    setInvalidLastNameMsg,
    setInvalidFirstName,
    setInvalidLastName,

    // validation
    invalidFirstName,
    invalidLastName,
    invalidDateOfBirth,
    invalidGender,
    invalidPhoneNumber,
    invalidJobOrOcupation,
    invalidAffiliation,
    invalidLocation,
    invalidAddress,
    invalidHowDoYouHearAboutBSSE,
    invalidCountry,

    // function helper
    handleSubmit,
    handlePhoneNumberChange,
    onChangeCaptcha,
    handleCheckboxChange,
    handleAddKeywords,
    handleRemoveKeywords,
  } = props;

  const genderDropdownOptions = [
    {
      label: "Select...",
      value: "Select...",
      disabled: true,
    },
    { label: "Male", value: "Male" },
    { label: "Female", value: "Female" },
    { label: "Rather not say", value: "Rather not say" },
  ];

  return (
    <CContainer>
      <CRow className="justify-content-center">
        <CImage
          src={logo}
          height={35}
          style={{
            filter: "invert(100%)",
            objectFit: "contain",
            top: "5%",
            position: "absolute",
          }}
        />
      </CRow>
      <div style={{ marginTop: "7.5em" }}></div>
      <CRow className="justify-content-center">
        <CCol md={9} lg={7} xl={8}>
          <CCard className="mx-4">
            <CCardBody className="p-4">
              <div className="row g-3 needs-validation">
                <h3
                  style={{
                    color: "#345AE0",
                    marginBottom: "1em",
                  }}
                >
                  General Information
                </h3>
                <CRow className="mb-2">
                  <CCol lg="12">
                    <div className="mb-3">
                      <CFormLabel
                        style={{ fontWeight: "500", fontSize: "16px" }}
                      >
                        Country
                      </CFormLabel>
                      <CMultiSelect
                        aria-label="Default select example"
                        options={countries}
                        value={country ? country : "Select..."}
                        invalid={invalidCountry}
                        feedbackInvalid="Please fill in this field"
                        onChange={(value) => setCountry(value[0])}
                        multiple={false}
                        visibleItems={5}
                        virtualScroller
                        optionsStyle="text"
                      />
                    </div>
                  </CCol>
                </CRow>
                <CRow className="mb-2">
                  <CCol lg="12">
                    <CFormLabel style={{ fontWeight: "500", fontSize: "16px" }}>
                      Phone number
                    </CFormLabel>
                    <CInputGroup className="mb-3">
                      <CDropdown variant="input-group">
                        <CDropdownToggle
                          color="secondary"
                          variant="outline"
                          onClick={() =>
                            setIsOpenDropdownPhoneNumber(
                              !isOpenDropdownPhoneNumber
                            )
                          }
                        >
                          {phoneNumber && phoneNumber.icon ? (
                            <CIcon icon={phoneNumber.icon} />
                          ) : (
                            "Country"
                          )}
                        </CDropdownToggle>
                      </CDropdown>
                      <CFormInput
                        aria-label="Text input with dropdown button"
                        invalid={invalidPhoneNumber}
                        feedbackInvalid="Please fill in this field"
                        placeholder="Phone number"
                        // floatingLabel="Phone number*"
                        value={
                          phoneNumber &&
                          phoneNumber.number &&
                          phoneNumber.phone_code
                            ? `(${phoneNumber.phone_code}) ${phoneNumber.number}`
                            : phoneNumber &&
                              phoneNumber.phone_code &&
                              !phoneNumber.number
                            ? `(${phoneNumber.phone_code}) `
                            : phoneNumber &&
                              phoneNumber.number &&
                              !phoneNumber.phone_code
                            ? phoneNumber.number
                            : ""
                        }
                        type="text"
                        onChange={({ target: { value } }) => {
                          let parts = value.split(" ");
                          parts.length > 1 ? parts.shift() : "";
                          let withoutCountryCode = parts.join(" ");
                          if (withoutCountryCode[0] !== "(") {
                            setPhoneNumber({
                              ...phoneNumber,
                              number: withoutCountryCode,
                            });
                          }
                        }}
                      />
                      {isOpenDropdownPhoneNumber ? (
                        <CMultiSelect
                          onChange={(value) => {
                            handlePhoneNumberChange(value[0]);
                          }}
                          multiple={false}
                          aria-label="Phone number"
                          id="exampleFormControlInput1"
                          options={countryOptions}
                          visibleItems={5}
                          virtualScroller
                          optionsStyle="text"
                          className="mt-1"
                        />
                      ) : null}
                    </CInputGroup>
                  </CCol>
                </CRow>
                <CRow className="mb-2">
                  <CCol lg="6">
                    <div className="mb-3">
                      <CFormLabel
                        style={{ fontWeight: "500", fontSize: "16px" }}
                      >
                        First name
                      </CFormLabel>
                      <CFormInput
                        placeholder="First name"
                        type="text"
                        value={firstName}
                        onChange={({ target: { value } }) => {
                          setFirstName(value),
                            setInvalidFirstName(false),
                            setInvalidFirstNameMsg("");
                        }}
                        feedbackInvalid={invalidFirstNameMsg}
                        invalid={invalidFirstName}
                      />
                    </div>
                  </CCol>
                  <CCol lg="6">
                    <div className="mb-3">
                      <CFormLabel
                        style={{ fontWeight: "500", fontSize: "16px" }}
                      >
                        Last name
                      </CFormLabel>
                      <CFormInput
                        placeholder="Last name"
                        type="text"
                        value={lastName}
                        onChange={({ target: { value } }) => {
                          setLastName(value),
                            setInvalidLastName(false),
                            setInvalidLastNameMsg("");
                        }}
                        feedbackInvalid={invalidLastNameMsg}
                        invalid={invalidLastName}
                      />
                    </div>
                  </CCol>
                </CRow>
                <CRow className="mb-2">
                  <CCol lg="6">
                    <div className="mb-3">
                      <CFormLabel
                        style={{ fontWeight: "500", fontSize: "16px" }}
                      >
                        Gender
                      </CFormLabel>
                      <CFormSelect
                        aria-label="Default select example"
                        options={genderDropdownOptions}
                        onChange={({ target: { value } }) => setGender(value)}
                        value={gender ? gender : "Select..."}
                        invalid={invalidGender}
                        feedbackInvalid="Please fill in this field"
                      />
                    </div>
                  </CCol>
                  <CCol lg="6">
                    <div className="mb-3">
                      <CFormLabel
                        style={{ fontWeight: "500", fontSize: "16px" }}
                      >
                        Date of Birth
                      </CFormLabel>
                      <CDatePicker
                        date={dateOfBirth}
                        onDateChange={(date) => setDateOfBirth(date)}
                        locale="en-US"
                        feedbackInvalid="Please fill in this field"
                        invalid={invalidDateOfBirth}
                      />
                    </div>
                  </CCol>
                </CRow>
                <CRow className="mb-2">
                  <CCol lg="6">
                    <div className="mb-3">
                      <CFormLabel
                        style={{ fontWeight: "500", fontSize: "16px" }}
                      >
                        Location
                      </CFormLabel>
                      <CFormInput
                        type="text"
                        placeholder="Location"
                        value={location}
                        onChange={({ target: { value } }) => setLocation(value)}
                        feedbackInvalid="Please fill in this field"
                        invalid={invalidLocation}
                      />
                    </div>
                  </CCol>
                  <CCol lg="6">
                    <div className="mb-3">
                      <CFormLabel
                        style={{ fontWeight: "500", fontSize: "16px" }}
                      >
                        Address
                      </CFormLabel>
                      <CFormInput
                        type="text"
                        placeholder="Address"
                        value={address}
                        onChange={({ target: { value } }) => setAddress(value)}
                        feedbackInvalid="Please fill in this field"
                        invalid={invalidAddress}
                      />
                    </div>
                  </CCol>
                </CRow>
                <CRow className="mb-2">
                  <CCol lg="6">
                    <div className="mb-3">
                      <CFormLabel
                        style={{ fontWeight: "500", fontSize: "16px" }}
                      >
                        Affiliation
                      </CFormLabel>
                      <CMultiSelect
                        aria-label="Default select example"
                        options={universityOptions}
                        value={affiliation ? affiliation : "Select..."}
                        feedbackInvalid="Please fill in this field"
                        invalid={invalidAffiliation}
                        onChange={(value) =>
                          setAffiliation(value && value[0] && value[0].value)
                        }
                        multiple={false}
                        visibleItems={5}
                        virtualScroller
                        optionsStyle="text"
                      />
                    </div>
                  </CCol>
                  <CCol lg="6">
                    <CFormLabel style={{ fontWeight: "500", fontSize: "16px" }}>
                      Affiliation Website URL
                    </CFormLabel>
                    <div className="d-flex gap-2">
                      <CFormInput
                        value={selectedKeywordAffiliationWebUrl}
                        onChange={({ target: { value } }) =>
                          setSelectedKeywordAffiliationWebUrl(value)
                        }
                        type="text"
                        placeholder="Please enter to submit URL(s)"
                        onKeyDown={(e) => handleAddKeywords(e, "affiliation")}
                        invalid={invalidAffiliationWebUrl}
                        feedbackInvalid="Please fill in this field"
                      />
                      <CButton
                        onClick={() =>
                          handleAddKeywords({ key: "Enter" }, "affiliation")
                        }
                      >
                        <CIcon icon={cilPlus} />
                      </CButton>
                    </div>
                    {keywordsAffiliationWebsiteURL &&
                    keywordsAffiliationWebsiteURL.length > 0 ? (
                      <CCol lg="12">
                        <div className="mb-3 mt-2">
                          <div className="justify-content-evenly">
                            {keywordsAffiliationWebsiteURL &&
                              keywordsAffiliationWebsiteURL.map((item, i) => (
                                <CBadge
                                  color="info"
                                  className="mb-1 ms-1"
                                  style={{
                                    padding: "8px",
                                    fontSize: "14px",
                                  }}
                                  key={i}
                                >
                                  {item}
                                  <CIcon
                                    icon={cilXCircle}
                                    className="ms-2"
                                    onClick={() =>
                                      handleRemoveKeywords(item, "affiliation")
                                    }
                                  />
                                </CBadge>
                              ))}
                          </div>
                        </div>
                      </CCol>
                    ) : null}
                  </CCol>
                </CRow>
                <CRow className="mb-0">
                  <CCol lg="6">
                    <div className="mb-3">
                      <CFormLabel
                        style={{ fontWeight: "500", fontSize: "16px" }}
                      >
                        Job/Occupation
                      </CFormLabel>
                      <CFormInput
                        type="text"
                        placeholder="Job / Ocupation"
                        value={jobOrOcupation}
                        onChange={({ target: { value } }) =>
                          setJobOrOcupation(value)
                        }
                        feedbackInvalid="Please fill in this field"
                        invalid={invalidJobOrOcupation}
                      />
                    </div>
                  </CCol>
                  <CCol lg="6">
                    <div className="mb-3">
                      <CFormLabel
                        style={{ fontWeight: "500", fontSize: "16px" }}
                      >
                        ORCID ID
                      </CFormLabel>
                      <CFormInput
                        type="text"
                        placeholder="ORCID ID (if any)"
                        value={orchidId}
                        onChange={({ target: { value } }) => setOrchidId(value)}
                      />
                    </div>
                  </CCol>
                </CRow>
                <CRow className="mb-0">
                  <CCol lg="6">
                    <div className="mb-3">
                      <CFormLabel
                        style={{ fontWeight: "500", fontSize: "16px" }}
                      >
                        How do you hear about BSSE?
                      </CFormLabel>
                      {howDoYouHearOptions &&
                        howDoYouHearOptions.length > 0 &&
                        howDoYouHearOptions.map((option, index) => {
                          const { label, checked } = option;
                          return (
                            <CFormCheck
                              key={index}
                              id="flexCheckChecked"
                              label={label}
                              defaultChecked={checked}
                              onChange={({ target: { checked } }) =>
                                handleCheckboxChange(index, checked)
                              }
                            />
                          );
                        })}
                    </div>
                  </CCol>
                  <CCol lg="6">
                    <CFormLabel style={{ fontWeight: "500", fontSize: "16px" }}>
                      Others
                    </CFormLabel>
                    <CFormInput
                      value={selectedKeywordBSSE}
                      onChange={({ target: { value } }) =>
                        setSelectedKeywordBSSE(value)
                      }
                      type="text"
                      placeholder="How do you hear about BSSE?"
                      onKeyDown={(e) => handleAddKeywords(e, "BSSE")}
                      invalid={invalidHowDoYouHearAboutBSSE}
                      feedbackInvalid="Please fill in this field"
                    />
                    {keywordsBSSE && keywordsBSSE.length > 0 ? (
                      <CCol lg="12">
                        <div className="mb-3 mt-2">
                          <div className="justify-content-evenly">
                            {keywordsBSSE &&
                              keywordsBSSE.map((item, i) => (
                                <CBadge
                                  color="info"
                                  className="mb-1 ms-1"
                                  style={{
                                    padding: "8px",
                                    fontSize: "14px",
                                  }}
                                  key={i}
                                >
                                  {item}
                                  <CIcon
                                    icon={cilXCircle}
                                    className="ms-2"
                                    onClick={() =>
                                      handleRemoveKeywords(item, "BSSE")
                                    }
                                  />
                                </CBadge>
                              ))}
                          </div>
                        </div>
                      </CCol>
                    ) : null}
                  </CCol>
                </CRow>
                <CRow>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                    }}
                  >
                    <ReCAPTCHA
                      sitekey="6Le7pvUaAAAAAAiPYg6vfzaD7kFju2jFBEYuvvaK"
                      onChange={onChangeCaptcha}
                    />
                    <label
                      style={{
                        marginLeft: "0.5em",
                        fontStyle: "normal",
                        fontWeight: "400",
                        fontSize: "12px",
                      }}
                    >
                      By signing up, I agree to BSSE{" "}
                      <a
                        // className="no-underline hover:underline"
                        href="/login"
                        style={{ textDecoration: "none", color: "#4285F4" }}
                      >
                        Terms of Services
                      </a>{" "}
                      and{" "}
                      <a
                        // className="no-underline hover:underline"
                        href="/login"
                        style={{ textDecoration: "none", color: "#4285F4" }}
                      >
                        Privacy Policy
                      </a>
                      . This page is protected by reCAPTCHA and is subject to
                      Google's Terms of Service and Privacy Policy.
                    </label>
                  </div>
                </CRow>
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                  <CButton
                    color="dark"
                    variant="outline"
                    type="submit"
                    onClick={(e) => handleSubmit(e)}
                  >
                    Submit
                  </CButton>
                </div>
              </div>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
      <div style={{ marginBottom: "7.5em" }}></div>
    </CContainer>
  );
};

export default RegisterSlideTwo;
