import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { CCol, CContainer, CRow, CImage, CButton } from "@coreui/react-pro";
import Cookies from "js-cookie";
import Axios from "src/helpers/axios";
import logo from "src/assets/brand/cloudxier_logo.png";

const Verification = () => {
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isSuccessVerifying, setIsSuccessVerifying] = useState(true);

  const token = Cookies.get("ut");

  useEffect(() => {
    if (token) {
      Axios({
        method: "get",
        url: "/users",
      })
        .then(({ data }) => {
          if (data) {
            navigate("/");
          }
        })
        .catch((err) => {
          console.log("Error:::", err);
        });
    }
  }, []);

  useEffect(() => {
    const token = params && params.token;
    if (token) {
      Axios({
        method: "get",
        url: `/users/verify/${token}`,
      })
        .then(({ data }) => {
          if (data) {
            const { success } = data || {};
            if (success) {
              setIsSuccessVerifying(true);
            }
          }
        })
        .catch((err) => {
          const errMessage =
            err &&
            err.response &&
            err.response.data &&
            err.response.data.message;
          console.log("Error:::", errMessage);
          if (errMessage) {
            dispatch({
              type: "SET_TOAST_MESSAGE",
              toastMessage: {
                isError: true,
                isShown: true,
                color: "danger",
                text: errMessage,
              },
            });
          }
        });
    } else {
      navigate("/");
    }
  }, []);

  return (
    <div className="bg-light min-vh-100 d-flex flex-row align-items-center">
      <CContainer>
        <CRow className="justify-content-center">
          <CImage
            src={logo}
            height={35}
            style={{
              filter: "invert(100%)",
              objectFit: "contain",
              top: "9%",
              position: "absolute",
            }}
          />
        </CRow>
        <CRow className="justify-content-center">
          {isSuccessVerifying ? (
            <CCol
              md={7}
              className="d-flex flex-column align-items-center justify-content-center"
            >
              <h3
                style={{
                  color: "#345AE0",
                  marginBottom: "0.75em",
                }}
              >
                Verification success
              </h3>
              <div
                style={{
                  color: "#000000",
                  fontSize: "17px",
                  fontWeight: "400",
                  fontStyle: "normal",
                  textAlign: "center",
                  marginBottom: "1em",
                }}
              >
                You have successfully verified your account. Please login to
                proceed further.
              </div>
              <CButton
                color="dark"
                className="px-4"
                variant="outline"
                type="submit"
                style={{
                  marginTop: "3em",
                  borderWidth: "1.5px",
                }}
                onClick={() => navigate("/")}
              >
                <div
                  style={{
                    color: "#000000",
                    fontWeight: "400",
                  }}
                >
                  Back to login page
                </div>
              </CButton>
            </CCol>
          ) : (
            <CCol
              md={7}
              className="d-flex flex-column align-items-center justify-content-center"
            >
              <span className="clearfix">
                <h4 className="pt-3">
                  Please wait while we verify your account...
                </h4>
              </span>
            </CCol>
          )}
        </CRow>
      </CContainer>
    </div>
  );
};

export default Verification;
