import React, { useEffect, useState } from "react";
import { CCol, CContainer, CRow, CImage, CButton } from "@coreui/react-pro";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Axios from "src/helpers/axios";
import logo from "src/assets/brand/cloudxier_logo.png";

// React countdown timer
import Countdown, { zeroPad } from "react-countdown";

// React OTP Input
import OTPInput from "otp-input-react";

const OTPModal = () => {
  const navigate = useNavigate();
  const { email } = useParams();
  const [otpCode, setOtpCode] = useState("");
  const [otpExpiration, setOtpExpiration] = useState(null);
  const [otpValidation, setOtpValidation] = useState(false);
  const [userCanRequestForNextOtpTime, setUserCanRequestForNextOtpTime] =
    useState("");
  const [userCanRequestForNextOtp, setUserCanRequestForNextOtp] =
    useState(false);
  const [count, setCount] = useState(0);
  const [count2, setCount2] = useState(0);

  const credentials = localStorage.getItem("credentials");

  // useEffect only running if userCanRequestForNextOtpTime state is defined
  useEffect(() => {
    const intervalId = setInterval(() => {
      if (
        userCanRequestForNextOtpTime &&
        !otpExpiration // preventing useState running when countdown is defined
      ) {
        if (userCanRequestForNextOtpTime < new Date()) {
          setUserCanRequestForNextOtp(true); // able to click resend otp button
          setOtpValidation(""); // remove all validation
          setCount(0); // stop the useEffect
        } else {
          setUserCanRequestForNextOtp(false); // disabling resend otp button
          setCount((count) => count + 1);
        }
      }
    }, 1000);
    return () => clearInterval(intervalId);
  }, [count > 0]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (
        otpExpiration &&
        !userCanRequestForNextOtpTime // preventing useState running when validation is defined
      ) {
        if (new Date(otpExpiration) < new Date()) {
          setUserCanRequestForNextOtp(true); // able to click resend otp button
          setOtpExpiration(null); // stop the useEffect
        } else {
          setUserCanRequestForNextOtp(false); // disabling resend otp button
          setCount2((count2) => count2 + 1);
        }
      }
    }, 1000);
    return () => clearInterval(intervalId);
  }, [otpExpiration]); // useEffect only running if userCanRequestForNextOtpTime state is defined

  useEffect(() => {
    if (email) {
      getOtp();
    }
  }, [email]);

  const getOtp = () => {
    Axios({
      method: "get",
      url: `/otp-verifications/${email}`,
    })
      .then(({ data }) => {
        if (data) {
          const { success, expired_date } = data;
          if (success) {
            setOtpExpiration(expired_date);
            setUserCanRequestForNextOtp(false);
          }
        }
      })
      .catch((err) => {
        if (
          err &&
          err.response &&
          err.response.data &&
          err.response.data.message &&
          err.response.data.userCanRequestForNextOtpTime
        ) {
          setOtpExpiration(null);
          setUserCanRequestForNextOtpTime(
            new Date(err.response.data.userCanRequestForNextOtpTime)
          );
          setOtpValidation(err.response.data.message);
          setCount(1);
        }
      });
  };

  const handleSubmitOtp = () => {
    if (otpCode && String(otpCode).length === 6) {
      Axios({
        method: "put",
        url: `/otp-verifications/verify`,
        data: { email, otp_code: otpCode },
      })
        .then(({ data }) => {
          if (data) {
            const { success } = data;
            if (success) {
              navigate("/");
            }
          }
        })
        .catch((err) => {
          if (
            err &&
            err.response &&
            err.response.data &&
            err.response.data.message
          ) {
            setUserCanRequestForNextOtp(true);
            setOtpValidation(err.response.data.message);
            setOtpExpiration(null);
          }
        });
    }
  };

  // Renderer callback with condition
  const countdownRenderer = ({ hours, minutes, seconds, completed }) => {
    if (completed) {
      // Render a completed state
      return null;
    } else {
      // Render a countdown
      return (
        <span style={{ fontSize: "14px", color: "black", marginTop: "1.5em" }}>
          {zeroPad(minutes)}:{zeroPad(seconds)}
        </span>
      );
    }
  };

  return (
    <div className="bg-light min-vh-100 d-flex flex-row align-items-center">
      <CContainer>
        <CRow className="justify-content-center">
          <CImage
            src={logo}
            height={35}
            style={{
              filter: "invert(100%)",
              objectFit: "contain",
              top: "9%",
              position: "absolute",
            }}
          />
        </CRow>
        {credentials ? (
          <CRow className="justify-content-center">
            <CCol
              md={7}
              className="d-flex flex-column align-items-center justify-content-center"
            >
              <h4
                style={{
                  color: "#000000",
                  marginBottom: "5em",
                }}
              >
                Login credentials invalid. You have reached too many login
                attempts
              </h4>
            </CCol>
          </CRow>
        ) : null}
        <CRow className="justify-content-center">
          <CCol
            md={7}
            className="d-flex flex-column align-items-center justify-content-center"
          >
            <h3
              style={{
                color: "#345AE0",
                marginTop: "-2em",
                marginBottom: "0.75em",
              }}
            >
              Enter your OTP Code
            </h3>
            <div
              style={{
                color: "#000000",
                fontSize: "17.3px",
                fontWeight: "400",
                fontStyle: "normal",
                textAlign: "center",
                marginBottom: "1em",
              }}
            >
              <b>We have sent a verification code to your email</b>
              <p> Please input code sent to your email address {email}</p>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center ",
                }}
              >
                <OTPInput
                  value={otpCode}
                  onChange={setOtpCode}
                  autoFocus
                  OTPLength={6}
                  otpType="number"
                  disabled={false}
                  inputStyles={{
                    marginTop: "1em",
                    width: "36px",
                    height: "50px",
                    backgroundColor: otpValidation ? "#FFD5C8" : "#E5E5E5",
                    borderColor: otpValidation ? "#CC3100" : "#345AE0",
                    borderTopColor: otpValidation ? "#CC3100" : "#345AE0",
                    borderLeftColor: otpValidation ? "#CC3100" : "#345AE0",
                    borderWidth: "2px",
                    borderShadow: "none",
                    borderRadius: "10px",
                    fontWeight: "700",
                    fontSize: "22px",
                  }}
                />
                {!otpValidation && otpExpiration ? (
                  <Countdown
                    date={otpExpiration}
                    renderer={countdownRenderer}
                  />
                ) : null}
              </div>
              {otpValidation ? (
                <div
                  style={{
                    marginTop: "1em",
                    textAlign: "center",
                    color: "#EB4335",
                  }}
                >
                  <p>{otpValidation}</p>
                </div>
              ) : null}
              <div
                style={{
                  marginTop: "2em",
                  textAlign: "center",
                  marginLeft: otpExpiration ? "-1.25em" : "0",
                }}
              >
                <p>
                  Haven’t received OTP code? Click{" "}
                  <span
                    style={
                      userCanRequestForNextOtp &&
                      new Date(otpExpiration) < new Date()
                        ? {
                            fontWeight: "700",
                            color: "#000000",
                            cursor: "pointer",
                          }
                        : {
                            fontWeight: "400",
                            color: "#AAAAAA",
                            fontStyle: "normal",
                          }
                    }
                    onClick={() =>
                      userCanRequestForNextOtp &&
                      new Date(otpExpiration) < new Date()
                        ? getOtp()
                        : null
                    }
                  >
                    Resend
                  </span>
                </p>
              </div>
            </div>
            <CButton
              color={String(otpCode).length !== 6 ? "secondary" : "dark"}
              className="px-4"
              variant="outline"
              type="submit"
              style={{
                marginTop: "3em",
                borderWidth: "1.5px",
              }}
              onClick={() =>
                String(otpCode).length !== 6 ? null : handleSubmitOtp()
              }
            >
              <div
                style={{
                  color: String(otpCode).length !== 6 ? "#AAAAAA" : "#000000",
                  fontWeight: "500",
                }}
              >
                Submit
              </div>
            </CButton>
          </CCol>
        </CRow>
      </CContainer>
    </div>
  );
};

export default OTPModal;
