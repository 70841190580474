export const singleOptions = (data, isUniversity) => {
  let options = []
  if (data && data.length > 0) {
    data.forEach((item) => {
      const id = item && item.id
      const name = item && item.name
      options.push({
        value: isUniversity ? name : id,
        text: name,
        params: {...item}
      })
    })
  } else {
    return options
  }
  return options
}

export const singleOptionsSelect = (data, isUniversity) => {
  let options = []
  if (data && data.length > 0) {
    data.forEach((item) => {
      const id = item && item.id
      const name = item && item.name
      options.push({
        label: name,
        value: isUniversity ? name : id,
        params: {...item}
      })
    })
  } else {
    return options
  }
  return options
}