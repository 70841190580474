import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import Cookies from "js-cookie";
import Axios from "src/helpers/axios";
import RegisterSlideOne from "./RegisterSlide/RegisterSlideOne";
import RegisterSlideTwo from "./RegisterSlide/RegisterSlideTwo";
import { singleOptions } from "src/helpers/format-options";
import * as icon from "@coreui/icons";
import moment from "moment";
import { joinArr } from "src/helpers/join-split-function";
import RegisterSuccess from "./RegisterSlide/RegisterSuccess";
import axios from "axios";

const Register = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const token = Cookies.get("ut");

  const [countries, setCountries] = useState([]);
  const [universityOptions, setUniversityOptions] = useState([]);
  const [countryOptions, setCountryOptions] = useState([]);

  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [password, setPassword] = useState("");
  const [repeatPassword, setRepeatPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const [isOpenRegisterSlideTwo, setIsOpenRegisterSlideTwo] = useState(false);

  const [dateOfBirth, setDateOfBirth] = useState("");
  const [phoneNumber, setPhoneNumber] = useState({
    number: "",
    icon: "",
    phone_code: "",
  });
  const [gender, setGender] = useState("");
  const [jobOrOcupation, setJobOrOcupation] = useState("");
  const [affiliation, setAffiliation] = useState("");
  const [location, setLocation] = useState("");
  const [address, setAddress] = useState("");
  const [orchidId, setOrchidId] = useState("");
  const [country, setCountry] = useState(null);

  const [invalidEmail, setInvalidEmail] = useState(false);
  const [invalidPassword, setInvalidPassword] = useState(false);
  const [invalidRepeatPassword, setInvalidRepeatPassword] = useState(false);
  const [invalidFirstName, setInvalidFirstName] = useState(false);
  const [invalidLastName, setInvalidLastName] = useState(false);
  const [invalidDateOfBirth, setInvalidDateOfBirth] = useState(false);
  const [invalidPhoneNumber, setInvalidPhoneNumber] = useState(false);
  const [invalidGender, setInvalidGender] = useState(false);
  const [invalidJobOrOcupation, setInvalidJobOrOcupation] = useState(false);
  const [invalidAffiliation, setInvalidAffiliation] = useState(false);
  const [invalidLocation, setInvalidLocation] = useState(false);
  const [invalidAddress, setInvalidAddress] = useState(false);
  const [invalidCountry, setInvalidCountry] = useState(false);
  const [invalidHowDoYouHearAboutBSSE, setInvalidHowDoYouHearAboutBSSE] =
    useState(false);
  const [invalidAffiliationWebUrl, setInvalidAffiliationWebUrl] =
    useState(false);

  const [invalidFirstNameMsg, setInvalidFirstNameMsg] = useState("");
  const [invalidLastNameMsg, setInvalidLastNameMsg] = useState("");
  const [invalidEmailMsg, setInvalidEmailMsg] = useState("");
  const [invalidPasswordMsg, setInvalidPasswordMsg] = useState("");
  const [invalidRepeatPasswordMsg, setInvalidRepeatPasswordMsg] = useState("");

  const [isOpenDropdownPhoneNumber, setIsOpenDropdownPhoneNumber] =
    useState(false);

  const [isCaptchaCorrect, setCaptchaCorrect] = useState(false);
  const [isOpenSuccessRegisterModal, setIsOpenSuccessRegisterModal] =
    useState(false);

  const [howDoYouHearOptions, setHowDoYouHearOptions] = useState([
    {
      checked: false,
      label: "Friends/colleague",
    },
    {
      checked: false,
      label: "Social media channel",
    },
    {
      checked: false,
      label: "Website",
    },
    {
      checked: false,
      label: "Google search",
    },
    {
      checked: false,
      label: "Promotional flyer",
    },
  ]);

  const [selectedKeywordBSSE, setSelectedKeywordBSSE] = useState("");
  const [keywordsBSSE, setKeywordsBSSE] = useState([]);
  const [
    selectedKeywordAffiliationWebUrl,
    setSelectedKeywordAffiliationWebUrl,
  ] = useState("");
  const [keywordsAffiliationWebsiteURL, setKeywordsAffiliationWebsiteURL] =
    useState([]);

  const handleCheckboxChange = (index, val) => {
    const newArray = [...howDoYouHearOptions];
    newArray[index].checked = val;
    setHowDoYouHearOptions(newArray);
  };

  const handleAddKeywords = (e, flagger) => {
    let prevKeywords;
    if (e && e.key == "Enter") {
      if (flagger === "affiliation") {
        prevKeywords = keywordsAffiliationWebsiteURL;
        prevKeywords = prevKeywords.map((item) => item.toLowerCase());
        if (
          prevKeywords.indexOf(selectedKeywordAffiliationWebUrl.toLowerCase()) <
          0
        ) {
          const websiteUrlRegex =
            /^((https?|ftp|smtp):\/\/)?(www.)?[a-z0-9]+\.[a-z]+(\/[a-zA-Z0-9#]+\/?)*$/;
          if (websiteUrlRegex.test(selectedKeywordAffiliationWebUrl)) {
            prevKeywords.push(selectedKeywordAffiliationWebUrl);
          } else {
            dispatch({
              type: "SET_TOAST_MESSAGE",
              toastMessage: {
                isError: true,
                isShown: true,
                color: "danger",
                text: "Please input the correct form of the website URL",
              },
            });
          }
        }
        setKeywordsAffiliationWebsiteURL(prevKeywords);
        setSelectedKeywordAffiliationWebUrl("");
      } else {
        prevKeywords = keywordsBSSE;
        prevKeywords = prevKeywords.map((item) => item.toLowerCase());
        if (prevKeywords.indexOf(selectedKeywordBSSE.toLowerCase()) < 0) {
          prevKeywords.push(selectedKeywordBSSE);
        }
        setKeywordsBSSE(prevKeywords);
        setSelectedKeywordBSSE("");
      }
    }
  };

  const handleRemoveKeywords = (deletedItem, flagger) => {
    let prevKeywords;
    if (deletedItem) {
      if (flagger === "affiliation") {
        prevKeywords = keywordsAffiliationWebsiteURL;
        prevKeywords = prevKeywords.filter((item) => item != deletedItem);
        setKeywordsAffiliationWebsiteURL(prevKeywords);
      } else {
        prevKeywords = keywordsBSSE;
        prevKeywords = prevKeywords.filter((item) => item != deletedItem);
        setKeywordsBSSE(prevKeywords);
      }
    }
  };

  const emailStorage = localStorage.getItem("email_storage");

  useEffect(() => {
    const afterRegister = localStorage.getItem("after_register");
    if (token && afterRegister && emailStorage && Boolean(afterRegister)) {
      setIsOpenRegisterSlideTwo(true);
    }
    getCountries();
    fetchAllUniversities();
  }, []);

  const fetchAllUniversities = () => {
    return new Promise((resolve, reject) => {
      axios({
        method: "get",
        url: "https://raw.githubusercontent.com/Hipo/university-domains-list/master/world_universities_and_domains.json",
      })
        .then(({ data }) => {
          setUniversityOptions(singleOptions(data, true));
          resolve(singleOptions(data, true));
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  const getCountries = () => {
    return new Promise((resolve, reject) => {
      Axios({
        method: "get",
        url: "/countries",
      })
        .then(({ data }) => {
          setCountries(singleOptions(data));
          setCountryOptions(createCountriesOptions(data));
          resolve(singleOptions(data));
        })
        .catch((err) => {
          const errMessage =
            err &&
            err.response &&
            err.response.data &&
            err.response.data.message;
          console.log("Error:::", errMessage);
          if (errMessage) {
            dispatch({
              type: "SET_TOAST_MESSAGE",
              toastMessage: {
                isError: true,
                isShown: true,
                color: "danger",
                text: errMessage,
              },
            });
          }
          reject();
        });
    });
  };

  const createCountriesOptions = (countries) => {
    const newCountries = countries.map((country) => {
      const { name } = country;
      const objCountry = {
        text: name,
        value: country,
      };
      return objCountry;
    });
    if (
      newCountries &&
      Array.isArray(newCountries) &&
      newCountries.length > 0
    ) {
      return newCountries;
    }
  };

  const createProfile = () => {
    let finalHowDoYouHearAboutBSSE = "";
    let finalHowDoYouHearAboutBSSEoptions = [];
    let finalHowDoYouHearAboutBSSEkeywords = keywordsBSSE;
    for (let h = 0; h < howDoYouHearOptions.length; h++) {
      const option = howDoYouHearOptions[h];
      const { checked, label } = option;
      if (checked) {
        finalHowDoYouHearAboutBSSEoptions.push(label);
      }
    }
    finalHowDoYouHearAboutBSSEoptions = joinArr(
      finalHowDoYouHearAboutBSSEoptions
    );
    finalHowDoYouHearAboutBSSEkeywords = joinArr(
      finalHowDoYouHearAboutBSSEkeywords
    );
    if (finalHowDoYouHearAboutBSSEoptions) {
      finalHowDoYouHearAboutBSSE += finalHowDoYouHearAboutBSSEoptions;
      if (finalHowDoYouHearAboutBSSEkeywords) {
        finalHowDoYouHearAboutBSSE =
          finalHowDoYouHearAboutBSSE + "," + finalHowDoYouHearAboutBSSEkeywords;
      }
    } else {
      finalHowDoYouHearAboutBSSE = finalHowDoYouHearAboutBSSEkeywords;
    }
    let data = {};
    data.email = email || emailStorage;
    data.first_name = firstName;
    data.last_name = lastName;
    data.country_id = country && country.value;
    data.phone_number = phoneNumber && phoneNumber.number;
    data.date_of_birth = moment(dateOfBirth).format();
    data.gender = gender;
    data.job_or_occupation = jobOrOcupation;
    data.affiliation = affiliation;
    data.location = location;
    data.address = address;
    if (orchidId) {
      data.orchid_id = orchidId;
    }
    data.how_do_you_hear_about_bsse = finalHowDoYouHearAboutBSSE;
    data.affiliation_website_url = joinArr(
      keywordsAffiliationWebsiteURL,
      "links"
    );
    Axios({
      method: "post",
      url: "/users/profile-create",
      data,
    })
      .then(() => {
        dispatch({
          type: "SET_TOAST_MESSAGE",
          toastMessage: {
            isError: false,
            isShown: true,
            color: "success",
            text: "Create profile success, please verify you account",
          },
        });
        localStorage.setItem("after_register", true);
        setTimeout(() => {
          setIsOpenRegisterSlideTwo(false);
          setIsOpenSuccessRegisterModal(true);
        }, 2000);
      })
      .catch((err) => {
        const errMessage =
          err && err.response && err.response.data && err.response.data.message;
        console.log("Error:::", errMessage);
        if (errMessage) {
          dispatch({
            type: "SET_TOAST_MESSAGE",
            toastMessage: {
              isError: true,
              isShown: true,
              color: "danger",
              text: errMessage,
            },
          });
        }
      });
  };

  const validatePassword = (password) => {
    const passwordRegex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d!@#$%^&*()_+={}\[\]|\\:;"'<>,.?/~`-]{8,}$/;
    if (passwordRegex.test(password)) {
      return true;
    } else {
      return false;
    }
  };

  const validateEmail = (email) => {
    const validEmailRegex =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9.-]+\.[a-zA-Z0-9-]{2,6}$/;
    if (email.match(validEmailRegex)) {
      return true;
    } else {
      return false;
    }
  };

  const handleValidationPageOne = () => {
    let emailInvalid = false;
    let passwordInvalid = false;
    let repeatPasswordInvalid = false;
    let emailInvalidMsg = "";
    let passwordInvalidMsg = "";
    let repeatPasswordInvalidMsg = "";

    if (!email) {
      emailInvalid = true;
      emailInvalidMsg = "Please fill in this field";
    } else {
      const isValidEmail = validateEmail(email);
      if (!isValidEmail) {
        emailInvalid = true;
        emailInvalidMsg = "Please enter a valid email format";
      }
    }
    if (!password) {
      passwordInvalid = true;
      passwordInvalidMsg = "Please fill in this field";
    } else {
      const isValidPassword = validatePassword(password);
      if (!isValidPassword) {
        passwordInvalid = true;
        passwordInvalidMsg =
          "Password should be at least 8 characters long, contain at least one uppercase letter, one lowercase letter, and one number.";
      }
    }
    if (!repeatPassword) {
      repeatPasswordInvalid = true;
      repeatPasswordInvalidMsg = "Please fill in this field";
    } else {
      const isValidRepeatPassword = validatePassword(repeatPassword);
      if (!isValidRepeatPassword) {
        repeatPasswordInvalid = true;
        repeatPasswordInvalidMsg =
          "Password should be at least 8 characters long, contain at least one uppercase letter, one lowercase letter, and one number.";
      } else {
        if (password !== repeatPassword) {
          repeatPasswordInvalid = true;
          repeatPasswordInvalidMsg = "Password did not match";
        }
      }
    }

    setInvalidEmail(emailInvalid);
    setInvalidPassword(passwordInvalid);
    setInvalidRepeatPassword(repeatPasswordInvalid);
    setInvalidEmailMsg(emailInvalidMsg);
    setInvalidPasswordMsg(passwordInvalidMsg);
    setInvalidRepeatPasswordMsg(repeatPasswordInvalidMsg);
    if (
      emailInvalid !== true &&
      passwordInvalid !== true &&
      repeatPasswordInvalid !== true
    ) {
      return true;
    } else {
      return false;
    }
  };

  const handleCreateNewUser = () => {
    Axios({
      method: "post",
      url: "/users/create",
      data: {
        email,
        password,
      },
    })
      .then(() => {
        dispatch({
          type: "SET_TOAST_MESSAGE",
          toastMessage: {
            isError: false,
            isShown: true,
            color: "success",
            text: "Register success, please verify you account",
          },
        });
        localStorage.setItem("email_storage", email);
        localStorage.setItem("after_register", true);
        setTimeout(() => {
          setIsOpenRegisterSlideTwo(true);
        }, 2000);
      })
      .catch((err) => {
        const errMessage =
          err && err.response && err.response.data && err.response.data.message;
        console.log("Error:::", errMessage);
        if (errMessage) {
          dispatch({
            type: "SET_TOAST_MESSAGE",
            toastMessage: {
              isError: true,
              isShown: true,
              color: "danger",
              text: errMessage,
            },
          });
        }
      });
  };

  const handleClickRegisterBtn = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const isValid = handleValidationPageOne();
    if (isValid) {
      handleCreateNewUser();
    }
    // const form = e.currentTarget;
    // if (!form.checkValidity()) {
    //   setValidatedSlideOne(true);
    // } else {
    //   const isValidEmail = validateEmail(email)
    //   const isValidPassword = validatePassword(password);
    //   if (!isValidEmail) {
    //     dispatch({
    //       type: "SET_TOAST_MESSAGE",
    //       toastMessage: {
    //         isError: true,
    //         isShown: true,
    //         color: "danger",
    //         text: "Please enter a valid email format",
    //       },
    //     });
    //   } else {
    //     if (!isValidPassword) {
    //       dispatch({
    //         type: "SET_TOAST_MESSAGE",
    //         toastMessage: {
    //           isError: true,
    //           isShown: true,
    //           color: "danger",
    //           text: "Password should be at least 8 characters long, contain at least one uppercase letter, one lowercase letter, and one number.",
    //         },
    //       });
    //     } else {
    //       if (password !== repeatPassword) {
    //         dispatch({
    //           type: "SET_TOAST_MESSAGE",
    //           toastMessage: {
    //             isError: true,
    //             isShown: true,
    //             color: "danger",
    //             text: "Password did not match",
    //           },
    //         });
    //       } else {
    //         Axios({
    //           method: "post",
    //           url: "/users/create",
    //           data: {
    //             email,
    //             password,
    //           },
    //         })
    //           .then(() => {
    //             dispatch({
    //               type: "SET_TOAST_MESSAGE",
    //               toastMessage: {
    //                 isError: false,
    //                 isShown: true,
    //                 color: "success",
    //                 text: "Register success, please verify you account",
    //               },
    //             });
    //             localStorage.setItem("email_storage", email);
    //             localStorage.setItem("after_register", true);
    //             setTimeout(() => {
    //               setIsOpenRegisterSlideTwo(true);
    //             }, 2000);
    //           })
    //           .catch((err) => {
    //             const errMessage =
    //               err &&
    //               err.response &&
    //               err.response.data &&
    //               err.response.data.message;
    //             console.log("Error:::", errMessage);
    //             if (errMessage) {
    //               dispatch({
    //                 type: "SET_TOAST_MESSAGE",
    //                 toastMessage: {
    //                   isError: true,
    //                   isShown: true,
    //                   color: "danger",
    //                   text: errMessage,
    //                 },
    //               });
    //             }
    //           });
    //       }
    //     }
    //   }
    // }
  };

  const handleValidationPageTwo = () => {
    let isValidCountry = false;
    let isValidFirstName = false;
    let isValidLastName = false;
    let isValidDateOfBirth = false;
    let isValidPhoneNumber = false;
    let isValidGender = false;
    let isValidJobOrOcupation = false;
    let isValidAffiliation = false;
    let isValidLocation = false;
    let isValidAddress = false;
    let isValidHowDoYouHearAboutBSSE = false;
    let isValidAffiliationWebUrl = false;

    let firstNameInvalidMsg = "";
    let lastNameInvalidMsg = "";

    if (!country) {
      isValidCountry = true;
    }
    if (!firstName) {
      isValidFirstName = true;
      firstNameInvalidMsg = "Please fill in this field";
    } else {
      if (firstName.length < 3) {
        isValidFirstName = true;
        firstNameInvalidMsg = "Please input minimal 3 characters";
      }
    }
    if (!lastName) {
      isValidLastName = true;
      lastNameInvalidMsg = "Please fill in this field";
    } else {
      if (lastName.length < 3) {
        isValidLastName = true;
        lastNameInvalidMsg = "Please input minimal 3 characters";
      }
    }
    if (!dateOfBirth) {
      isValidDateOfBirth = true;
    }
    if (phoneNumber && !phoneNumber.number) {
      isValidPhoneNumber = true;
    }
    if (!gender) {
      isValidGender = true;
    }
    if (!jobOrOcupation) {
      isValidJobOrOcupation = true;
    }
    if (!affiliation) {
      isValidAffiliation = true;
    }
    if (!location) {
      isValidLocation = true;
    }
    if (!address) {
      isValidAddress = true;
    }

    const hasCheckedItem = howDoYouHearOptions.some(
      (item) => item.checked === true
    );
    if (!hasCheckedItem) {
      if (keywordsBSSE.length <= 0) {
        isValidHowDoYouHearAboutBSSE = true;
      }
    }
    if (keywordsAffiliationWebsiteURL.length <= 0) {
      isValidAffiliationWebUrl = true;
    }

    setInvalidFirstNameMsg(firstNameInvalidMsg);
    setInvalidLastNameMsg(lastNameInvalidMsg);
    setInvalidFirstName(isValidFirstName);
    setInvalidLastName(isValidLastName);
    setInvalidDateOfBirth(isValidDateOfBirth);
    setInvalidPhoneNumber(isValidPhoneNumber);
    setInvalidGender(isValidGender);
    setInvalidJobOrOcupation(isValidJobOrOcupation);
    setInvalidAffiliation(isValidAffiliation);
    setInvalidLocation(isValidLocation);
    setInvalidAddress(isValidAddress);
    setInvalidCountry(isValidCountry);
    setInvalidHowDoYouHearAboutBSSE(isValidHowDoYouHearAboutBSSE);
    setInvalidAffiliationWebUrl(isValidAffiliationWebUrl);

    if (
      isValidFirstName !== true &&
      isValidLastName !== true &&
      isValidDateOfBirth !== true &&
      isValidPhoneNumber !== true &&
      isValidGender !== true &&
      isValidJobOrOcupation !== true &&
      isValidAffiliation !== true &&
      isValidLocation !== true &&
      isValidAddress !== true &&
      isValidCountry !== true &&
      isValidHowDoYouHearAboutBSSE !== true &&
      isValidAffiliationWebUrl !== true
    ) {
      return true;
    } else {
      return false;
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (!isCaptchaCorrect) {
      dispatch({
        type: "SET_TOAST_MESSAGE",
        toastMessage: {
          isError: true,
          isShown: true,
          color: "danger",
          text: "Please check the captcha box before you proceed",
        },
      });
    } else {
      const isValid = handleValidationPageTwo();
      if (isValid) {
        createProfile();
      }
    }
  };

  const handlePhoneNumberChange = (val) => {
    const { value } = val;
    const countryIcon = icon[`cif${value.country_code}`];
    setPhoneNumber({
      ...phoneNumber,
      icon: countryIcon,
      phone_code: value.phone_code,
    });
    setIsOpenDropdownPhoneNumber(false);
  };

  // Google ReCaptcha
  const onChangeCaptcha = (value) => {
    if (value) {
      setCaptchaCorrect(true);
    } else {
      setCaptchaCorrect(false);
    }
  };

  return (
    <div className="bg-light min-vh-100 d-flex flex-row align-items-center">
      {isOpenSuccessRegisterModal ? (
        <RegisterSuccess
          navigate={navigate}
          email={email}
          emailStorage={emailStorage}
        />
      ) : (
        <>
          {!isOpenRegisterSlideTwo ? (
            <RegisterSlideOne
              email={email}
              password={password}
              repeatPassword={repeatPassword}
              showPassword={showPassword}
              invalidEmail={invalidEmail}
              invalidEmailMsg={invalidEmailMsg}
              invalidPassword={invalidPassword}
              invalidPasswordMsg={invalidPasswordMsg}
              invalidRepeatPassword={invalidRepeatPassword}
              invalidRepeatPasswordMsg={invalidRepeatPasswordMsg}
              setEmail={setEmail}
              setShowPassword={setShowPassword}
              setRepeatPassword={setRepeatPassword}
              setPassword={setPassword}
              handleClickRegisterBtn={handleClickRegisterBtn}
            />
          ) : (
            <RegisterSlideTwo
              // state
              countries={countries}
              countryOptions={countryOptions}
              isOpenDropdownPhoneNumber={isOpenDropdownPhoneNumber}
              firstName={firstName}
              lastName={lastName}
              dateOfBirth={dateOfBirth}
              gender={gender}
              phoneNumber={phoneNumber}
              jobOrOcupation={jobOrOcupation}
              affiliation={affiliation}
              location={location}
              address={address}
              orchidId={orchidId}
              country={country}
              howDoYouHearOptions={howDoYouHearOptions}
              selectedKeywordBSSE={selectedKeywordBSSE}
              keywordsBSSE={keywordsBSSE}
              universityOptions={universityOptions}
              selectedKeywordAffiliationWebUrl={
                selectedKeywordAffiliationWebUrl
              }
              invalidAffiliationWebUrl={invalidAffiliationWebUrl}
              keywordsAffiliationWebsiteURL={keywordsAffiliationWebsiteURL}
              invalidFirstNameMsg={invalidFirstNameMsg}
              invalidLastNameMsg={invalidLastNameMsg}
              // state setter
              setIsOpenDropdownPhoneNumber={setIsOpenDropdownPhoneNumber}
              setFirstName={setFirstName}
              setLastName={setLastName}
              setDateOfBirth={setDateOfBirth}
              setGender={setGender}
              setPhoneNumber={setPhoneNumber}
              setJobOrOcupation={setJobOrOcupation}
              setAffiliation={setAffiliation}
              setLocation={setLocation}
              setAddress={setAddress}
              setOrchidId={setOrchidId}
              setCountry={setCountry}
              setSelectedKeywordBSSE={setSelectedKeywordBSSE}
              setSelectedKeywordAffiliationWebUrl={
                setSelectedKeywordAffiliationWebUrl
              }
              setInvalidFirstNameMsg={setInvalidFirstNameMsg}
              setInvalidLastNameMsg={setInvalidLastNameMsg}
              setInvalidFirstName={setInvalidFirstName}
              setInvalidLastName={setInvalidLastName}
              // validation
              invalidFirstName={invalidFirstName}
              invalidLastName={invalidLastName}
              invalidDateOfBirth={invalidDateOfBirth}
              invalidGender={invalidGender}
              invalidPhoneNumber={invalidPhoneNumber}
              invalidJobOrOcupation={invalidJobOrOcupation}
              invalidAffiliation={invalidAffiliation}
              invalidLocation={invalidLocation}
              invalidAddress={invalidAddress}
              invalidHowDoYouHearAboutBSSE={invalidHowDoYouHearAboutBSSE}
              invalidCountry={invalidCountry}
              // function helper
              handleSubmit={handleSubmit}
              handlePhoneNumberChange={handlePhoneNumberChange}
              onChangeCaptcha={onChangeCaptcha}
              handleCheckboxChange={handleCheckboxChange}
              handleAddKeywords={handleAddKeywords}
              handleRemoveKeywords={handleRemoveKeywords}
            />
          )}
        </>
      )}
    </div>
  );
};

export default Register;
