import React, { Component, Suspense } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import {
  CSpinner,
  CToaster,
  CToast,
  CToastBody,
  CToastClose,
} from "@coreui/react-pro";
import "./scss/style.scss";
import Login from "./views/pages/Login";
import Register from "./views/pages/Register";
import Verification from "./views/pages/Verification";
import ForgotPassword from "./views/pages/ForgotPassword";
import ResetPassword from "./views/pages/ResetPassword";
import OTPModal from "./views/pages/OTPModal";

import "./App.css";

// Containers
const DefaultLayout = React.lazy(() => import("./layout/DefaultLayout"));

function App() {
  const dispatch = useDispatch();

  const toastMessage = useSelector((state) => state.toastMessage);

  return (
    <div className="App">
      <CToaster placement="top-end">
        <CToast
          autohide={true}
          visible={toastMessage.isShown}
          color={toastMessage.color}
          className="text-white align-items-center"
          onClose={() => {
            dispatch({
              type: "SET_TOAST_MESSAGE",
              toastMessage: {
                isError: false,
                isShown: false,
                color: "primary",
                text: "",
              },
            });
          }}
        >
          <div className="d-flex">
            <CToastBody>{toastMessage.text}</CToastBody>
            <CToastClose className="me-2 m-auto" white />
          </div>
        </CToast>
      </CToaster>
      <BrowserRouter>
        <Suspense fallback={<CSpinner color="primary" />}>
          <Routes>
            <Route path="*" name="Home" element={<DefaultLayout />} />
            <Route path="/login" name="login" element={<Login />} />
            <Route path="/register" name="register" element={<Register />} />
            <Route
              path="/account/otp/:email"
              name="Otp Verification"
              element={<OTPModal />}
            />
            <Route
              path="/account/verify/:token"
              name="verification"
              element={<Verification />}
            />
            <Route
              path="/account/forgot"
              name="Forgot Password"
              element={<ForgotPassword />}
            />
            <Route
              path="/account/forgot/verify/:token"
              name="Reset Password"
              element={<ResetPassword />}
            />
          </Routes>
        </Suspense>
      </BrowserRouter>
    </div>
  )

}

export default App;
