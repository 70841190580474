const joinArr = (array, key) => {
  let propsArr;
  if (key && key === "links") {
    propsArr = array.join(" ");
  } else {
    propsArr = array.join(",");
  }
  return propsArr;
};

const splitString = (str, key) => {
  let propsArr;
  if (key && key === "links") {
    propsArr = str.split(" ");
  } else {
    propsArr = str.split(",");
  }
  return propsArr;
};

const handleClassLabel = (str) => {
  return str.split(" ").join("-")
}

export { joinArr, splitString, handleClassLabel };
