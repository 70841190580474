import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  CButton,
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CForm,
  CFormInput,
  CInputGroup,
  CRow,
  CFormLabel,
  CImage,
} from "@coreui/react-pro";
import Cookies from "js-cookie";
import Axios from "src/helpers/axios";

// React Google Recaptcha
import ReCAPTCHA from "react-google-recaptcha";

// icons
import showPasswordIcon from "../../assets/icons/showPasswordIcon.png";
import hidePasswordIcon from "../../assets/icons/hidePasswordIcon.png";
import logo from "src/assets/brand/cloudxier_logo.png";

const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const token = Cookies.get("ut");

  const afterRegister = localStorage.getItem("after_register");

  useEffect(() => {
    localStorage.setItem("credentials", "")
    if (token) {
      if (afterRegister && Boolean(afterRegister)) {
        localStorage.setItem("after_register", "");
        localStorage.setItem("email_storage", "");
        Cookies.remove("ut");
        dispatch({ type: "SET_USER", user: {} });
      } else {
        Axios({
          method: "get",
          url: "/users",
        })
          .then(({ data }) => {
            if (data) {
              navigate("/");
            }
          })
          .catch((err) => {
            console.log("Error:::", err);
          });
      }
    }
  }, []);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isCaptchaCorrect, setCaptchaCorrect] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const [invalidEmail, setInvalidEmail] = useState(false)
  const [invalidPassword, setInvalidPassword] = useState(false)
  const [invalidEmailMsg, setInvalidEmailMsg] = useState(false)
  const [invalidPasswordMsg, setInvalidPasswordMsg] = useState(false)

  const login = () => {
    return new Promise((resolve, reject) => {
      Axios({
        method: "post",
        url: "/users/login",
        data: {
          email: email,
          password: password,
        },
      })
        .then(({ data }) => {
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  const validateEmail = (email) => {
    const validEmailRegex =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9.-]+\.[a-zA-Z0-9-]{2,6}$/;
    if (email.match(validEmailRegex)) {
      return true;
    } else {
      return false;
    }
  };

  const handleValidation = () => {
    let emailValidation = false
    let emailValidationMsg = ""
    let passwordValidation = false
    let passwordValidationMsg = ""

    if (!email) {
      emailValidationMsg = "Please enter your email"
      emailValidation = true
    } else {
      const isValidEmail = validateEmail(email);
      if (!isValidEmail) {
        emailValidationMsg = "Please enter a valid email format";
        emailValidation = true;
      }
    }

    if (!password) {
      passwordValidationMsg = "Please enter your password"
      passwordValidation = true
    }

    setInvalidEmail(emailValidation)
    setInvalidPassword(passwordValidation)
    setInvalidEmailMsg(emailValidationMsg)
    setInvalidPasswordMsg(passwordValidationMsg)

    if (emailValidation !== true && passwordValidation !== true) {
      return true
    } else {
      return false
    }
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (!isCaptchaCorrect) {
      dispatch({
        type: "SET_TOAST_MESSAGE",
        toastMessage: {
          isError: true,
          isShown: true,
          color: "danger",
          text: "Please check the captcha box before you proceed",
        },
      });
    } else {
      const isValid = handleValidation()
      if (isValid) {
        login()
        .then((result) => {
          if (result) {
            const { success } = result || {};
            if (success) {
              navigate(`/account/otp/${email}`);
            }
          }
        })
        .catch((err) => {
          const errMessage =
            err &&
            err.response &&
            err.response.data &&
            err.response.data.message;
          const navigateToOTPpage =
            err &&
            err.response &&
            err.response.data &&
            err.response.data.useOTP;
          console.log("Error:::", errMessage);
          if (errMessage) {
            setInvalidEmail(true)
            setInvalidPassword(true)
            setInvalidEmailMsg(errMessage)
            setInvalidPasswordMsg(errMessage)
            dispatch({
              type: "SET_TOAST_MESSAGE",
              toastMessage: {
                isError: true,
                isShown: true,
                color: "danger",
                text: errMessage,
              },
            });
            if (navigateToOTPpage) {
              setTimeout(() => {
                localStorage.setItem('credentials', true)
                navigate(`/account/otp/${email}`);
                setInvalidEmailMsg("")
                setInvalidPasswordMsg("")
                setInvalidEmail(false)
                setInvalidPassword(false)
              }, 2000);
            } 
          }
        });
      } 
    }
  };

  // Google ReCaptcha
  const onChangeCaptcha = (value) => {
    if (value) {
      setCaptchaCorrect(true);
    } else {
      setCaptchaCorrect(false);
    }
  };

  return (
    <div className="bg-light min-vh-100 d-flex flex-row align-items-center">
      <CContainer>
        <CRow className="justify-content-center">
          <CImage
            src={logo}
            height={35}
            style={{
              filter: "invert(100%)",
              objectFit: "contain",
              top: "9%",
              position: "absolute",
            }}
          />
        </CRow>
        <CRow className="justify-content-center">
          <CCol md={8}>
            <CCardGroup>
              <CCard className="p-4">
                <CCardBody>
                <h2>Login</h2>
                    <CInputGroup className="mb-3">
                      <CCol>
                        <CFormLabel
                          style={{ fontWeight: "500", fontSize: "16px" }}
                        >
                          Email Address
                        </CFormLabel>
                        <CFormInput
                          placeholder="Fill in your email address here..."
                          autoComplete="email"
                          type="email"
                          value={email}
                          invalid={invalidEmail}
                          onChange={({ target: { value } }) => setEmail(value)}
                          feedbackInvalid={invalidEmailMsg}
                          required
                        />
                      </CCol>
                    </CInputGroup>
                    <CInputGroup className="mb-3">
                      <CCol>
                        <CRow>
                          <CFormLabel
                            style={{ fontWeight: "500", fontSize: "16px" }}
                          >
                            Password
                            <img
                              src={
                                showPassword
                                  ? showPasswordIcon
                                  : hidePasswordIcon
                              }
                              onClick={() => setShowPassword(!showPassword)}
                              style={{
                                width: "18px",
                                height: "18px",
                                marginLeft: "0.5em",
                                marginTop: "-3px",
                              }}
                            />
                          </CFormLabel>
                        </CRow>
                        <CFormInput
                          type={showPassword ? "text" : "password"}
                          placeholder="Please input password..."
                          autoComplete="current-password"
                          value={password}
                          invalid={invalidPassword}
                          onChange={({ target: { value } }) =>
                            setPassword(value)
                          }
                          feedbackInvalid={invalidPasswordMsg}
                          feedbackValid="Looks good!"
                          required
                        />
                      </CCol>
                    </CInputGroup>
                    <CInputGroup className="mb-4">
                      <ReCAPTCHA
                        sitekey="6Le7pvUaAAAAAAiPYg6vfzaD7kFju2jFBEYuvvaK"
                        onChange={onChangeCaptcha}
                      />
                    </CInputGroup>
                    <CRow>
                      <CCol xs={6}>
                        <CButton color="primary" className="px-4" type="submit" onClick={(e) => handleSubmit(e)}>
                          Login
                        </CButton>
                      </CCol>
                      <CCol xs={6} className="text-right">
                        <Link to="/account/forgot">
                          <CButton color="link" className="px-0">
                            Forgot password?
                          </CButton>
                        </Link>
                      </CCol>
                    </CRow>
                </CCardBody>
              </CCard>
              <CCard
                className="text-white bg-primary py-4"
                style={{ width: "44%" }}
              >
                <CCardBody className="text-center">
                  <div>
                    <h2>Sign up</h2>
                    <p>Don't have an account yet?</p>
                    <Link to="/register">
                      <CButton
                        color="primary"
                        className="mt-3"
                        active
                        tabIndex={-1}
                      >
                        Register Now!
                      </CButton>
                    </Link>
                  </div>
                </CCardBody>
              </CCard>
            </CCardGroup>
          </CCol>
        </CRow>
      </CContainer>
    </div>
  );
};

export default Login;
