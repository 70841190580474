import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  CButton,
  CCard,
  CCardBody,
  CCol,
  CContainer,
  CForm,
  CFormInput,
  CInputGroup,
  CInputGroupText,
  CRow,
  CImage,
  CFormLabel,
} from "@coreui/react-pro";
import CIcon from "@coreui/icons-react";
import { cilLockLocked } from "@coreui/icons";
import Cookies from "js-cookie";
import Axios from "src/helpers/axios";
import logo from "src/assets/brand/cloudxier_logo.png";
import showPasswordIcon from "../../assets/icons/showPasswordIcon.png";
import hidePasswordIcon from "../../assets/icons/hidePasswordIcon.png";

const ResetPassword = () => {
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const userToken = Cookies.get("ut");
  const token = params && params.token;

  const [isVerified, setVerified] = useState(false);
  const [user, setUser] = useState({});

  const [password, setPassword] = useState("");
  const [invalidPassword, setInvalidPassword] = useState(false);
  const [invalidPasswordMsg, setInvalidPasswordMsg] = useState("");

  const [repeatPassword, setRepeatPassword] = useState("");
  const [invalidRepeatPassword, setInvalidRepeatPassword] = useState(false);
  const [invalidRepeatPasswordMsg, setInvalidRepeatPasswordMsg] = useState("");

  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    if (userToken) {
      Axios({
        method: "get",
        url: "/users",
      })
        .then(({ data }) => {
          if (data) {
            navigate("/");
          }
        })
        .catch((err) => {
          console.log("Error:::", err);
        });
    }
  }, []);

  useEffect(() => {
    if (token) {
      Axios({
        method: "get",
        url: `/users/forgot/verify/${token}`,
      })
        .then(({ data }) => {
          setVerified(true);
          setUser(data && data.user);
        })
        .catch((err) => {
          const errMessage =
            err &&
            err.response &&
            err.response.data &&
            err.response.data.message;
          console.log("Error:::", errMessage);
          if (errMessage) {
            dispatch({
              type: "SET_TOAST_MESSAGE",
              toastMessage: {
                isError: true,
                isShown: true,
                color: "danger",
                text: errMessage,
              },
            });
            navigate("/");
          }
        });
    } else {
      navigate("/");
    }
  }, []);

  const resetPassword = () => {
    Axios({
      method: "post",
      url: "/users/forgot/reset",
      data: {
        password: password,
        token: token,
      },
    })
      .then(() => {
        dispatch({
          type: "SET_TOAST_MESSAGE",
          toastMessage: {
            isError: false,
            isShown: true,
            color: "success",
            text: "Reset password success, please re-login",
          },
        });
        setTimeout(() => {
          navigate("/");
        }, 2000);
      })
      .catch((err) => {
        const errMessage =
          err && err.response && err.response.data && err.response.data.message;
        console.log("Error:::", errMessage);
        if (errMessage) {
          dispatch({
            type: "SET_TOAST_MESSAGE",
            toastMessage: {
              isError: true,
              isShown: true,
              color: "danger",
              text: errMessage,
            },
          });
        }
      });
  };

  const validatePassword = (password) => {
    const passwordRegex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d!@#$%^&*()_+={}\[\]|\\:;"'<>,.?/~`-]{8,}$/;
    if (passwordRegex.test(password)) {
      return true;
    } else {
      return false;
    }
  };

  const handleValidation = () => {
    let passwordInvalid = false;
    let repeatPasswordInvalid = false;
    let passwordInvalidMsg = "";
    let repeatPasswordInvalidMsg = "";

    if (!password) {
      passwordInvalid = true;
      passwordInvalidMsg = "Please fill in this field";
    } else {
      const isValidPassword = validatePassword(password);
      if (!isValidPassword) {
        passwordInvalid = true;
        passwordInvalidMsg =
          "Password should be at least 8 characters long, contain at least one uppercase letter, one lowercase letter, and one number.";
      }
    }

    if (!repeatPassword) {
      repeatPasswordInvalid = true;
      repeatPasswordInvalidMsg = "Please fill in this field";
    } else {
      const isValidRepeatPassword = validatePassword(repeatPassword);
      if (!isValidRepeatPassword) {
        repeatPasswordInvalid = true;
        repeatPasswordInvalidMsg =
          "Password should be at least 8 characters long, contain at least one uppercase letter, one lowercase letter, and one number.";
      } else {
        if (password !== repeatPassword) {
          repeatPasswordInvalid = true;
          repeatPasswordInvalidMsg = "Password did not match";
        }
      }
    }

    setInvalidPassword(passwordInvalid);
    setInvalidRepeatPassword(repeatPasswordInvalid);
    setInvalidPasswordMsg(passwordInvalidMsg);
    setInvalidRepeatPasswordMsg(repeatPasswordInvalidMsg);

    if (passwordInvalid !== true && repeatPasswordInvalid !== true) {
      return true;
    } else {
      return false;
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const isValid = handleValidation();
    if (isValid) {
      resetPassword();
    }
  };

  if (!isVerified) {
    return (
      <div className="bg-light min-vh-100 d-flex flex-row align-items-center">
        <CContainer>
          <CRow className="justify-content-center">
            <CCol md={6}>
              <span className="clearfix">
                <h4 className="pt-3">
                  Please wait while we verify your link...
                </h4>
              </span>
            </CCol>
          </CRow>
        </CContainer>
      </div>
    );
  } else {
    return (
      <div className="bg-light min-vh-100 d-flex flex-row align-items-center">
        <CContainer>
          <CRow className="justify-content-center">
            <CImage
              src={logo}
              height={35}
              style={{
                filter: "invert(100%)",
                objectFit: "contain",
                top: "9%",
                position: "absolute",
              }}
            />
          </CRow>
          <CRow className="justify-content-center">
            <CCol md={9} lg={7} xl={6}>
              <CCard className="mx-4">
                <CCardBody className="p-4">
                  <CForm
                    className="row g-3 needs-validation"
                    onSubmit={handleSubmit}
                  >
                    <h2
                      style={{
                        color: "#345AE0",
                        marginBottom: "0.15em",
                      }}
                    >
                      Enter your new password
                    </h2>
                    <div
                      style={{
                        color: "#000000",
                        fontSize: "17px",
                        fontWeight: "400",
                        fontStyle: "normal",
                        textAlign: "left",
                      }}
                    >
                      Your new password must be different from the previous
                      passwords.
                    </div>
                    <CRow className="mt-5">
                      <CCol lg="12">
                        <div className="mb-3">
                          <CRow>
                            <CFormLabel
                              style={{ fontWeight: "500", fontSize: "16px" }}
                            >
                              Password
                              <img
                                src={
                                  showPassword
                                    ? showPasswordIcon
                                    : hidePasswordIcon
                                }
                                onClick={() => setShowPassword(!showPassword)}
                                style={{
                                  width: "18px",
                                  height: "18px",
                                  marginLeft: "0.5em",
                                  marginTop: "-3px",
                                }}
                              />
                            </CFormLabel>
                          </CRow>
                          <CFormInput
                            type={showPassword ? "text" : "password"}
                            placeholder="Password"
                            autoComplete="current-password"
                            value={password}
                            onChange={({ target: { value } }) =>
                              setPassword(value)
                            }
                            invalid={invalidPassword}
                            feedbackInvalid={invalidPasswordMsg}
                          />
                        </div>
                      </CCol>
                      <CCol lg="12">
                        <div className="mb-3">
                          <CRow>
                            <CFormLabel
                              style={{ fontWeight: "500", fontSize: "16px" }}
                            >
                              Retype password
                              <img
                                src={
                                  showPassword
                                    ? showPasswordIcon
                                    : hidePasswordIcon
                                }
                                onClick={() => setShowPassword(!showPassword)}
                                style={{
                                  width: "18px",
                                  height: "18px",
                                  marginLeft: "0.5em",
                                  marginTop: "-3px",
                                }}
                              />
                            </CFormLabel>
                          </CRow>
                          <CFormInput
                            type={showPassword ? "text" : "password"}
                            placeholder="Repeat password"
                            value={repeatPassword}
                            onChange={({ target: { value } }) =>
                              setRepeatPassword(value)
                            }
                            invalid={invalidRepeatPassword}
                            feedbackInvalid={invalidRepeatPasswordMsg}
                          />
                        </div>
                      </CCol>
                    </CRow>
                    <div className="d-grid gap-2 col-6 mx-auto">
                      <CButton
                        color={
                          !password || !repeatPassword ? "secondary" : "dark"
                        }
                        variant="outline"
                        type="submit"
                        style={{ marginBottom: "0.3em" }}
                        disabled={!password || !repeatPassword}
                      >
                        Reset password
                      </CButton>
                    </div>
                  </CForm>
                </CCardBody>
              </CCard>
            </CCol>
          </CRow>
        </CContainer>
      </div>
    );
  }
};

export default ResetPassword;
