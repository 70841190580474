import React from "react";
import {
  CButton,
  CCard,
  CCardBody,
  CCol,
  CContainer,
  CForm,
  CFormLabel,
  CFormInput,
  CRow,
  CImage,
} from "@coreui/react-pro";
import showPasswordIcon from "../../../assets/icons/showPasswordIcon.png";
import hidePasswordIcon from "../../../assets/icons/hidePasswordIcon.png";
import logo from "src/assets/brand/cloudxier_logo.png";

const RegisterSlideOne = (props) => {
  const {
    handleClickRegisterBtn,
    email,
    password,
    repeatPassword,
    showPassword,
    setShowPassword,
    setRepeatPassword,
    setEmail,
    setPassword,
    invalidEmail,
    invalidEmailMsg,
    invalidPassword,
    invalidPasswordMsg,
    invalidRepeatPassword,
    invalidRepeatPasswordMsg
  } = props;
  return (
    <CContainer>
      <CRow className="justify-content-center">
        <CImage
          src={logo}
          height={35}
          style={{
            filter: "invert(100%)",
            objectFit: "contain",
            top: "9%",
            position: "absolute",
          }}
        />
      </CRow>
      <CRow className="justify-content-center">
        <CCol md={9} lg={7} xl={8}>
          <CCard className="mx-4">
            <CCardBody className="p-4">
              <CForm
                className="row g-3 needs-validation"
                onSubmit={handleClickRegisterBtn}
              >
                <h3 style={{ color: "#345AE0" }}>Register to BSSE</h3>
                <div
                  style={{
                    color: "#000000",
                    fontSize: "17px",
                    fontWeight: "700",
                    fontStyle: "normal",
                    marginBottom: "1em",
                  }}
                >
                  Please fill in these fields to proceed your account
                  registration on Business Sustainability and Social Enterprise
                  (BSSE) Journals Portal.
                </div>
                <CRow className="mb-3">
                  <CCol lg="12">
                    <div className="mb-3">
                      <CFormLabel
                        style={{ fontWeight: "500", fontSize: "16px" }}
                      >
                        Email Address
                      </CFormLabel>
                      <CFormInput
                        placeholder="Email"
                        autoComplete="email"
                        type="email"
                        value={email}
                        onChange={({ target: { value } }) => setEmail(value)}
                        invalid={invalidEmail}
                        feedbackInvalid={invalidEmailMsg}
                      />
                    </div>
                  </CCol>
                </CRow>
                <CRow className="mb-3">
                  <CCol lg="6">
                    <div className="mb-3">
                      <CRow>
                        <CFormLabel
                          style={{ fontWeight: "500", fontSize: "16px" }}
                        >
                          Password
                          <img
                            src={
                              showPassword ? showPasswordIcon : hidePasswordIcon
                            }
                            onClick={() => setShowPassword(!showPassword)}
                            style={{
                              width: "18px",
                              height: "18px",
                              marginLeft: "0.5em",
                              marginTop: "-3px",
                            }}
                          />
                        </CFormLabel>
                      </CRow>
                      <CFormInput
                        type={showPassword ? "text" : "password"}
                        placeholder="Password"
                        autoComplete="current-password"
                        value={password}
                        onChange={({ target: { value } }) => setPassword(value)}
                        invalid={invalidPassword}
                        feedbackInvalid={invalidPasswordMsg}
                      />
                    </div>
                  </CCol>
                  <CCol lg="6">
                    <div className="mb-3">
                      <CRow>
                        <CFormLabel
                          style={{ fontWeight: "500", fontSize: "16px" }}
                        >
                          Retype password
                          <img
                            src={
                              showPassword ? showPasswordIcon : hidePasswordIcon
                            }
                            onClick={() => setShowPassword(!showPassword)}
                            style={{
                              width: "18px",
                              height: "18px",
                              marginLeft: "0.5em",
                              marginTop: "-3px",
                            }}
                          />
                        </CFormLabel>
                      </CRow>
                      <CFormInput
                        type={showPassword ? "text" : "password"}
                        placeholder="Repeat password"
                        value={repeatPassword}
                        onChange={({ target: { value } }) =>
                          setRepeatPassword(value)
                        }
                        invalid={invalidRepeatPassword}
                        feedbackInvalid={invalidRepeatPasswordMsg}
                      />
                    </div>
                  </CCol>
                </CRow>
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                  <CButton
                    color={!email || !password || !repeatPassword ? "secondary" : "success"}
                    variant="outline"
                    type="submit"
                    disabled={!email || !password || !repeatPassword}
                  >
                    Register
                  </CButton>
                </div>
              </CForm>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
    </CContainer>
  );
};

export default RegisterSlideOne;
